<template>
  <div class="lxwm">
    <div>
      <img src="../assets/image/product/phone.png">
      <p>18651649391</p>
    </div>
    <div>
      <img src="../assets/image/product/msg.png">
      <p>496127015@163.com</p>
    </div>
    <div class="wchart">
      <img src="../assets/image/product/wchart.png">
      <p>扫一扫加微信</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "contact",
  data(){
    return {
      
    };
  },
  mounted() {
     
  },
  methods: {
     
  },
}
</script>
<style lang="scss" scoped>
.lxwm{
  width: 100%;
  display: flex;
  margin: 40px auto;
  justify-content: space-between;
  position: absolute;
  left: 0%;
  top: 64%;
  margin-top: 60px;
  div{
    height: 130px;
    float: left;
    width: 25%;
    margin-left: 8%;
    background: #FFFFFF;
    border-radius: 10px;
    text-align: center;
    img{
      width: 30px;
      margin-top: 32px;
    }
    p{
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-top: 20px;
    }
    &:nth-child(1){
      margin-left: 0;
      img{
        width: 25px;
        margin-top: 30px;
      }
    }
  }
  .wchart{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 100px;
      height: 100px;
      margin: 0;
    }
    p{
      font-size: 15px;
      font-weight: bold;
      letter-spacing: 2px;
      margin: 0;
      margin-left: 20px;
    }
  }
}
</style>
