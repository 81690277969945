<template>
  <div class="yj-frame-main">
    <div class="up-banner">
      <div class="product">
        <h3>
          <typed-ment id="ais" strings="海事北斗AIS双向收发5G智能一体机"></typed-ment>
        </h3>
        <img class="proImg" src="../../assets/image/product/003.png" alt="">
        <h4>联系我们</h4>
        <contact-us></contact-us>
      </div>
    </div>
  </div>
</template>
<script>
import contactUs from '@/components/contactUs.vue'
export default {
  name: "ais",
  components: {
    contactUs
  },
  data(){
    return {
    
    }
  },
  mounted() {

  },
  methods: {
   
  },
}
</script>
<style lang="scss" scoped>
.up-banner{
  background:url("~@/assets/image/product/ais.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
	width: 100%;
  height: calc(100vh - 80px);
  .proImg{
    width: 350px;
    top: 15%;
  }
}
</style>